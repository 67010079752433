@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
body {
  font-family: 'Montserrat', cursive;
}

.home-image {
  background-image: url('./assets/images/bg-img-1.webp');
  background-position: center;
  background-size: cover;
  transition: background-image 0.5s ease-in-out;
  @media only screen and (max-device-width: 768px){
    background-color: #001C34;
    background-image: none !important;
  }
}

.auth .footer {
  background-color: #001C34;
}

.sidebar-open .footer {
  width: 400px;
}

.footer {
  transition: width .5s cubic-bezier(.77,0,.175,1);
  font-weight: 500;
  justify-content: center;
  color: white;
  width: 100vw;
  display: flex;
  align-items: center;
  padding: 10px 5px;
  gap: 5px;
  @media only screen and (max-device-width: 768px){
    background-color: #001C34;
    background-image: none !important;
  }
}

.footer div {
  width: 147.5px;
  text-align: center
}

.partner-logo {
  height: 30px;
  cursor: pointer;
  margin: auto;
}

.image-2{
  background-image: url('./assets/images/bg-img-2.webp');

}

.auth-background {
  background-image: url('./assets/images/authScreen.png');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.pricing-image {
  background-image: url('./assets/images/pricing.jpeg');
  background-position: center;
  background-size: cover;
}

.fullscreen {
  position:absolute;
  top:0px;
  right:0px;
  bottom:0px;
  left:0px;
}

.sidebar {
  background: white;
  bottom: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
  transform: translate3d(105%,0,0);
  width: calc(100% - 400px);
  height: 100%;
  transition: transform .5s cubic-bezier(.77,0,.175,1);
  z-index: 50;
  box-shadow: 0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%);
  @media (max-width:768px)  {
    width: 100%;

  }

}

.sidebar-open .sidebar{    
  transform: none !important;
}
.sidebar-open .bg-left-area{
  width: 100% !important; 
  background-color:#001C34 !important;
}
.bg-left-area{
  transition: width 0.7s;
}

.upload {
  transform: none;
  transition: transform .5s;
}

.upload .no-connection-info {
  position: absolute;
  top: 0;
  bottom:0;
  left: 0;
  right: 0;
  background-color: #000000a9;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-weight: 500;
  color: rgb(255, 202, 202);
  z-index: 100 !important;
}


.main-menu{    
  transform: none;
  transition: transform .5s;
}
button[type=submit]{
  box-shadow: none !important;
}
.sidebar-open .upload{    
  transform: translateX(calc(-9.3%)) !important;
}

.sidebar-open .main-menu{    
  transform: translateY(-60px);
}

.logo-blue {
  color: #4076bc !important;
}

.logo-blue:active {
  color: #1b4d8f !important;
}

.logo-blue:disabled {
  color: #838383 !important;
}

.bg-logo-blue {
  background-color: #4076bc !important;
}

.bg-logo-blue:hover {
  background-color: #1565c0 !important;
}

.border-logo-blue {
  border-color: #4076bc !important;
}

.logo-blue:hover .primary-link:hover{
  color: #1565c0 !important;
}

.spin {
  -webkit-animation:spin 1.5s linear infinite;
  -moz-animation:spin 1.5s linear infinite;
  animation:spin 1.5s linear infinite;
}

@-moz-keyframes spin { 
  100% { -moz-transform: rotate(360deg); } 
}
@-webkit-keyframes spin { 
  100% { -webkit-transform: rotate(360deg); } 
}
@keyframes spin { 
  100% { 
      -webkit-transform: rotate(360deg); 
      transform:rotate(360deg); 
  } 
}

.logo-animation {
  -webkit-animation:loading-animation 3s infinite;
  -moz-animation:loading-animation 3s infinite;
  animation:loading-animation 3s infinite;
}

.load-animation {
  -webkit-animation:entering 3s cubic-bezier(0.15, 0.62, 0.37, 0.93);
  -moz-animation:entering 3s cubic-bezier(0.15, 0.62, 0.37, 0.93);
  animation:entering 3s cubic-bezier(0.15, 0.62, 0.37, 0.93);
}

@-moz-keyframes loading-animation{
  0%{
      opacity:0
  }
  35%{
      opacity:.5
  }
  70%{
      opacity:0
  }
  to{
      opacity:0
  }
}

@-webkit-keyframes loading-animation{
  0%{
      opacity:0
  }
  35%{
      opacity:.5
  }
  70%{
      opacity:0
  }
  to{
      opacity:0
  }
}

@keyframes loading-animation{
  0%{
      opacity:0
  }
  35%{
      opacity:.5
  }
  70%{
      opacity:0
  }
  to{
      opacity:0
  }
}


@-moz-keyframes entering{
  0%{
      opacity:0
  }
  to{
      opacity:1
  }
}

@-webkit-keyframes entering{
  0%{
      opacity:0
  }
  to{
      opacity:1
  }
}

@keyframes entering{
  0%{
      opacity:0
  }
  to{
      opacity:1
  }
}
